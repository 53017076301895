import React from "react"
import { LocationProvider } from '@reach/router';
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import TourismHero from "../components/sustainable_tourism/TourismHero"
import TourismContent from "../components/sustainable_tourism/TourismContent"
import ContactLead from "../components/ContactLead"


const Philhost = () => {
  return (
    <>
      <Seo title="Sustainable Tourism | Philhost" />
      <BlurredSection>
        <LocationProvider>
          <TourismHero events="philhost"/>
          <TourismContent />
        </LocationProvider>
        <ContactLead />
      </BlurredSection>
    </>
  )
}

export default Philhost
